<template>
  <div class="total-index">
    <BasicHeader
      :scrollY="scrollY"
      @expandNav="openNav"
      @goPage="goPage"
      :tab="currentNav"
    />
    <div id="line"></div>
    <DropDownHeader
      v-show="showNav"
      :height="navHeight"
      @closeNav="closeNav"
      @goPage="goPage"
    />
    <IndexBanner @goPage="goPage" />
    <FirstIndex v-if="currentNav == 'index'" />
    <AboutUs v-if="currentNav == 'about'" />
    <ProductionIntroduction v-if="currentNav == 'prod'" />
    <ContactUs v-if="currentNav == 'contact'" />
    <PublicFooter :nav="currentNav" @goPage="goPage" />
    <BasicSvg iconClass="to-top" className="to-top-icon" @click="toTop" />
  </div>
</template>
<script>
import BasicHeader from "../components/header/BasicHeader.vue";
import IndexBanner from "../components/banner/IndexBanner.vue";
import DropDownHeader from "../components/header/DropDownHeader.vue";
import FirstIndex from "../components/contentPanel/FirstIndex.vue";
import PublicFooter from "../components/footer/PublicFooter.vue";
import AboutUs from "../components/contentPanel/AboutUs.vue";
import ProductionIntroduction from "../components/contentPanel/ProductionIntroduction.vue";
import ContactUs from "../components/contentPanel/ContactUs.vue";
export default {
  name: "totalIndex",
  components: {
    BasicHeader,
    IndexBanner,
    DropDownHeader,
    FirstIndex,
    PublicFooter,
    AboutUs,
    ProductionIntroduction,
    ContactUs,
  },
  data() {
    return {
      scrollY: 0,
      navHeight: "0px",
      showNav: false,
      currentNav: "index",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    openNav(height) {
      this.navHeight = height + "px";
      this.showNav = true;
    },
    closeNav() {
      this.showNav = false;
    },
    goPage(page) {
      this.currentNav = page;
      this.toTop();
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            80;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.total-index {
  #line {
    width: 100%;
    height: 0;
  }
  .to-top-icon {
    @media screen and (orientation: portrait) {
      position: fixed;
      bottom: 8vw;
      right: 3vw;
      width: 15vw;
      height: 15vw;
    }
    @media screen and (orientation: landscape) {
      position: fixed;
      bottom: 2vw;
      right: 1vw;
      width: 6vw;
      height: 6vw;
    }
  }
}
</style>