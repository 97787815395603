<template>
  <div class="about-us">
    <div class="float-right-row">
      <div class="img-box">
        <img src="../../assets/images/company-tel.png" class="img-tel" />
        <img src="../../assets/images/company-pc.png" class="img-pc" />
      </div>
      <div class="details-box">
        <div class="flex-header">
          <div class="title">企业介绍</div>
          <BasicSvg className="logo-img" iconClass="logo-black" />
        </div>
        <div class="title-bottom">Enterprise introduction</div>
        <div class="description">
          成都一猫一狗科技有限公司,成立于2019年,为动物医疗机构提供SAAS管理平台、临床决策支持系统(CDSS)以及营销推广工具。提升医院人效、降低管理成本、拓展获客渠道。
        </div>
      </div>
    </div>
    <div class="flex-header flex-header-team">
      <div class="title">核心团队</div>
      <BasicSvg className="arrow-img" iconClass="arrow-right" />
    </div>
    <div class="title-bottom title-bottom-team">Core team</div>
    <div class="float-left-row float-left-row-first">
      <div class="img-box">
        <img
          v-if="isPortrait"
          src="../../assets/images/dayu-tel.png"
          class="img-member"
        />
        <img v-else src="../../assets/images/dayu-pc.png" class="img-member" />
      </div>
      <div class="details-box">
        <div class="flex-header">
          <div class="title">于涛</div>
          <div class="title-extra">Yu Tao</div>
        </div>
        <div class="title-bottom">创始人&CMO</div>
        <div class="description">
          13年互联网营销、9年社交网络推广从业经验,哈佛商业评论、COM训练营讲师、曾供职于优客控股、中坤地产集团、
          xanadu(旅游B2B平台)及7年创业经验国内最早一批社交媒体数据研究者,服务于英特尔、三星、LG等500强企业,及奥美中国、新浪微博官方数据合作伙伴
        </div>
      </div>
    </div>
    <div class="float-right-row blue-bg-row">
      <div class="img-box">
        <img src="../../assets/images/taiyi.png" class="img-member" />
      </div>
      <div class="details-box">
        <div class="flex-header">
          <div class="title">马杰</div>
          <div class="title-extra">Ma Jie</div>
        </div>
        <div class="title-bottom">联合创始人&CTO</div>
        <div class="description">
          毕业于成都东软学院、前优客逸家高级研发和C端技术负责人,负责核心业务的架构设计和实现主导优客星空SAAS平台的开发工作
        </div>
      </div>
    </div>
    <div class="float-left-row">
      <div class="img-box">
        <img
          v-if="isPortrait"
          src="../../assets/images/longzhu-tel.png"
          class="img-member"
        />
        <img v-else src="../../assets/images/longzhu-pc.png" class="img-member" />
      </div>
      <div class="details-box">
        <div class="flex-header">
          <div class="title">李伯悦</div>
          <div class="title-extra">Li Bo Yue</div>
        </div>
        <div class="title-bottom">产品副总裁</div>
        <div class="description">
          负责公司产品设计及售后服务管理，曾供职于智联招聘、医联、新瑞鹏宠物医疗集团、同仁堂健康药业，10多年互联网产品设计、研发、运营从业经验
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutUs",
  computed: {
    isPortrait() {
      return JSON.parse(localStorage.getItem("1mao1gou.isPortrait")) || "";
    },
  },
};
</script>
<style lang="less" scoped>
.about-us {
  @media screen and (orientation: portrait) {
    padding-bottom: 4vw;
  }
  text-align: left;
  .float-right-row,
  .float-left-row {
    @media screen and (orientation: portrait) {
      padding: 6vw 0;
    }
    @media screen and (orientation: landscape) {
      padding: 5vw 17vw 5vw 15vw;
    }
  }
  .float-right-row {
    @media screen and (orientation: landscape) {
      .img-box {
        float: right;
      }
    }
  }
  .float-left-row {
    @media screen and (orientation: landscape) {
      display: flex;
      padding: 4vw 17vw 5vw 10vw;
    }
  }
  .float-left-row-first {
    @media screen and (orientation: landscape) {
      padding-top: 1vw;
    }
  }
  .blue-bg-row {
    background: #f4f6fc;
    @media screen and (orientation: portrait) {
      padding: 3vw 0 4vw;
    }
    @media screen and (orientation: landscape) {
      padding-top: 1vw;
      padding-bottom: 6.5vw;
    }
  }
  .img-box {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    @media screen and (orientation: portrait) {
      width: 90vw;
      height: 64vw;
      .img-pc {
        display: none;
      }
    }
    @media screen and (orientation: landscape) {
      width: 26vw;
      height: 19.5vw;
      .img-tel {
        display: none;
      }
    }
    .img-member,
    .img-pc,
    .img-tel {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .details-box {
    @media screen and (orientation: portrait) {
      width: 90vw;
      margin: 0 auto;
      padding-top: 5vw;
    }
    @media screen and (orientation: landscape) {
      width: 50%;
      padding-top: 5vw;
    }
  }
  .title-extra {
    @media screen and (orientation: portrait) {
      margin-left: 2vw;
      font-size: 4vw;
    }
    @media screen and (orientation: landscape) {
      margin-left: 1vw;
      font-size: 1vw;
    }
  }
  .title-extra,
  .title-bottom,
  .description {
    color: #282938;
    opacity: 0.6;
  }
  .title-bottom {
    @media screen and (orientation: portrait) {
      margin-top: 2vw;
      font-size: 4vw;
    }
    @media screen and (orientation: landscape) {
      margin-top: 0.5vw;
      font-size: 1vw;
    }
  }
  .title-bottom-team {
    opacity: 0.7;
    font-weight: 500;
    @media screen and (orientation: portrait) {
      padding-left: 5vw;
    }
    @media screen and (orientation: landscape) {
      padding-left: 15vw;
    }
  }
  .description {
    @media screen and (orientation: portrait) {
      font-size: 3.5vw;
      line-height: 7vw;
      margin-top: 5vw;
    }
    @media screen and (orientation: landscape) {
      font-size: 1vw;
      line-height: 1.6vw;
      margin-top: 1.5vw;
    }
  }
  .flex-header {
    display: flex;
    align-items: center;
    .title {
      color: #232536;
      font-weight: 600;
      @media screen and (orientation: portrait) {
        font-size: 7.5vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 2.3vw;
      }
    }
    .logo-img {
      @media screen and (orientation: portrait) {
        margin-left: 2vw;
        width: 32vw;
        height: 10vw;
      }
      @media screen and (orientation: landscape) {
        margin-left: 1vw;
        width: 7.5vw;
        height: 2vw;
      }
    }
    .arrow-img {
      @media screen and (orientation: portrait) {
        margin-left: 2vw;
        width: 7vw;
        height: 5vw;
      }
      @media screen and (orientation: landscape) {
        margin-left: 1vw;
        width: 2vw;
        height: 1vw;
      }
    }
  }
  .flex-header-team {
    @media screen and (orientation: portrait) {
      padding-left: 5vw;
      margin-top: 6vw;
    }
    @media screen and (orientation: landscape) {
      padding-left: 15vw;
      margin-top: 1vw;
      .title {
        font-size: 1.8vw;
      }
    }
  }
}
</style>