<template>
  <div class="contact-us">
    <div class="text-content">
      <div class="title-box">
        <div>联系我们</div>
        <div>Contact us</div>
      </div>
      <div class="contact-way" v-for="way in contactWays" :key="way.icon">
        <BasicSvg :iconClass="way.icon" className="icon" />
        <div class="text" :class="way.icon == 'phone' ? 'bold-text' : ''">
          {{ way.detail }}
        </div>
      </div>
    </div>
    <img v-if="isPortrait" src="../../assets/images/official-account-tel.png" class="qrcode" />
    <img v-else src="../../assets/images/official-account-pc.png" class="qrcode" />
  </div>
</template>
<script>
export default {
  name: "contactUs",
  computed: {
    isPortrait() {
      return JSON.parse(localStorage.getItem("1mao1gou.isPortrait")) || "";
    },
  },
  data() {
    return {
      contactWays: [
        {
          icon: "address",
          detail: "北京市朝阳区高碑店北花园388号华汇大厦B座五层",
        },
        {
          icon: "email",
          detail: "yichen@1mao1gou.com",
        },
        {
          icon: "phone",
          detail: "152-0147-8450",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.contact-us {
  box-sizing: border-box;
  @media screen and (orientation: portrait) {
    padding: 10vw 0 5vw;
  }
  @media screen and (orientation: landscape) {
    display: flex;
    justify-content: space-between;
    padding: 6vw 15vw;
    align-items: center;
  }
  .title-box {
    @media screen and (orientation: portrait) {
      margin-bottom: 5vw;
    }
    @media screen and (orientation: landscape) {
      display: flex;
      align-items: center;
      margin-bottom: 1.5vw;
    }
    div:first-child {
      font-weight: 600;
      color: #232536;
      @media screen and (orientation: portrait) {
        font-size: 5.5vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 2.3vw;
      }
    }
    div:nth-child(2) {
      color: #282938;
      opacity: 0.6;
      @media screen and (orientation: portrait) {
        font-size: 4vw;
        line-height: 8vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 1vw;
        margin-left: 0.8vw;
      }
    }
  }
  .contact-way {
    display: flex;
    @media screen and (orientation: portrait) {
      margin-top: 3.5vw;
      width: 100%;
      justify-content: center;
    }
    @media screen and (orientation: landscape) {
      align-items: center;
      margin-bottom: 1vw;
    }
    .icon {
      @media screen and (orientation: portrait) {
        width: 7vw;
        height: 7vw;
      }
      @media screen and (orientation: landscape) {
        width: 1.5vw;
        height: 1.5vw;
        flex-shrink: 0;
      }
    }
    .text {
      color: #232536;
      @media screen and (orientation: portrait) {
        margin-left: 2vw;
        font-size: 4vw;
        line-height: 8vw;
      }
      @media screen and (orientation: landscape) {
        margin-left: 0.8vw;
        font-size: 1vw;
      }
    }
    .bold-text {
      font-weight: 600;
      color: #232536;
      @media screen and (orientation: portrait) {
        font-size: 5.5vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 1.6vw;
      }
    }
  }
  .qrcode {
    @media screen and (orientation: portrait) {
      width: 32vw;
      height: 38vw;
      margin-top: 8vw;
    }
    @media screen and (orientation: landscape) {
      width: 11vw;
      height: 13vw;
    }
  }
}
</style>