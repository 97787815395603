import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import { Carousel, CarouselItem, Button } from 'element-ui';
import App from './App.vue'
import "./assets/icons"

Vue.config.productionTip = false
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Button);

new Vue({
    render: h => h(App),
}).$mount('#app')