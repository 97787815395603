<template>
  <div class="basic-header" :class="scrollY ? 'white-bg' : ''">
    <div class="header-content">
      <BasicSvg
        className="logo-img"
        :iconClass="scrollY ? 'logo-black' : 'logo-white'"
        @click="goPage('index')"
      />
      <div
        class="more"
        :class="scrollY ? 'border-more' : ''"
        @click="expandNav"
      >
        <img class="more-img" :src="scrollY ? blackMore : whiteMore" />
      </div>
      <div class="tabs-pc" :class="scrollY ? 'black-tab' : ''">
        <div
          v-for="tab in tabs"
          :key="tab.value"
          @click="goPage(tab.value)"
          class="tab-item"
          :class="activeTab == tab.value ? 'blue-tab' : ''"
        >
          <el-button
            v-if="tab.value == 'contact'"
            type="primary"
            class="contact-btn"
          >
            {{ tab.name }}<i class="el-icon-right" />
          </el-button>
          <span v-else>{{ tab.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import whiteMore from "../../assets/images/more_white.png";
import blackMore from "../../assets/images/more_black.png";
export default {
  name: "basicHeader",
  props: ["scrollY", "tab"],
  data() {
    return {
      whiteMore,
      blackMore,
      activeTab: "index",
      tabs: [
        {
          name: "首页",
          value: "index",
        },
        {
          name: "关于我们",
          value: "about",
        },
        {
          name: "产品介绍",
          value: "prod",
        },
        {
          name: "SaaS管理系统",
          value: "saas",
        },
        {
          name: "联系我们",
          value: "contact",
        },
      ],
    };
  },
  methods: {
    expandNav() {
      this.$emit(
        "expandNav",
        localStorage.getItem("1mao1gouOfficial.bannerHeight")
      );
    },
    goPage(page) {
      if (page == "saas") {
        window.open("https://vets.chongaipet.com/login", "_blank");
      } else {
        this.activeTab = page;
        this.$emit("goPage", page);
      }
    },
  },
  watch: {
    tab(val) {
      this.activeTab = val;
    },
  },
};
</script>
<style lang="less">
.basic-header {
  .el-tabs__nav-wrap::after {
    position: static !important;
  }
}
</style>
<style lang="less" scoped>
.basic-header {
  @media screen and (orientation: portrait) {
    height: 18vw;
  }
  @media screen and (orientation: landscape) {
    height: 5vw;
  }
  width: 100%;
  position: fixed;
  transition: all 0.5s linear;
  z-index: 99;
  .header-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    @media screen and (orientation: portrait) {
      padding: 0 5vw;
    }
    @media screen and (orientation: landscape) {
      padding: 0 15vw;
    }
    .logo-img {
      @media screen and (orientation: portrait) {
        width: 32vw;
        height: 10vw;
      }
      @media screen and (orientation: landscape) {
        width: 8vw;
        height: 2vw;
      }
    }
    .more {
      @media screen and (orientation: portrait) {
        width: 10vw;
        height: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.21);
        box-sizing: border-box;
      }
      @media screen and (orientation: landscape) {
        display: none;
      }
      .more-img {
        width: 4vw;
        height: 3.5vw;
      }
    }
    .border-more {
      border: 1px solid #e2e9ed;
    }
    .tabs-pc {
      color: #fff;
      font-size: 1vw;
      @media screen and (orientation: portrait) {
        display: none;
      }
      @media screen and (orientation: landscape) {
        display: flex;
        align-items: center;
      }
      .tab-item {
        cursor: pointer;
        & + .tab-item {
          margin-left: 3vw;
        }
        &:hover {
          color: #208ffd;
        }
      }
      .blue-tab {
        color: #208ffd;
        font-weight: 600;
      }
      .contact-btn {
        padding: 0.8vw 1.2vw;
        font-size: 1vw;
      }
    }
    .black-tab {
      color: #23354c;
    }
  }
}
.white-bg {
  background: #fff;
  box-shadow: 0px 4px 12px rgba(176, 176, 176, 0.25);
  transition: all 0.5s linear;
}
</style>