<template>
  <div class="drop-down-header">
    <div class="mask" @click="closeNav"></div>
    <div class="nav-content">
      <div class="white-bg-header">
        <div class="header-content">
          <BasicSvg
            className="logo-img"
            iconClass="logo-black"
            @click="goPage('index')"
          />
          <div class="close" @click="closeNav">
            <BasicSvg className="close-img" iconClass="close" />
          </div>
        </div>
      </div>
      <div
        class="nav-panel"
        v-for="item in navList"
        :key="item.title"
        @click="goPage(item.value)"
      >
        <div class="title">
          {{ item.title }}
        </div>
        <div class="second-title" v-for="nav in item.navs" :key="nav">
          {{ nav }}
        </div>
      </div>
      <div class="contact-button" @click="goPage('contact')">联系我们</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dropDownHeader",
  props: ["height"],
  data() {
    return {
      currentNav: "",
      navList: [
        {
          title: "产品",
          value: "prod",
          navs: ["SaaS管理系统", "产品介绍"],
        },
        {
          title: "关于我们",
          value: "about",
          navs: ["企业介绍", "核心团队"],
        },
      ],
    };
  },
  methods: {
    closeNav() {
      this.$emit("closeNav");
    },
    goPage(id) {
      this.currentNav = id;
      this.$emit("goPage", id);
      this.closeNav();
    },
  },
};
</script>
<style lang="less" scoped>
.drop-down-header {
  .mask {
    width: 100%;
    height: 100%;
    background: rgba(62, 80, 98, 0.7);
    z-index: 999;
    position: fixed;
  }
  .nav-content {
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 9999;
    padding-bottom: 11vw;
    .white-bg-header {
      width: 100%;
      height: 18vw;
      margin-bottom: 7vw;
      .header-content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        padding: 0 5vw;
        box-sizing: border-box;
        .logo-img {
          width: 32vw;
          height: 10vw;
        }
        .close {
          width: 10vw;
          height: 10vw;
          display: flex;
          align-items: center;
          justify-content: center;
          .close-img {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }
    .nav-panel {
      width: 80%;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 7vw;
      .title {
        font-size: 4.5vw;
        font-weight: 600;
        color: #333;
      }
      .second-title {
        font-size: 4vw;
        margin-top: 3vw;
        color: #666;
      }
    }
    .contact-button {
      width: 80%;
      margin: 0 auto;
      padding: 4vw 0;
      border: 1px solid #e2e9ed;
      box-sizing: border-box;
      border-radius: 1vw;
      font-size: 4vw;
      color: #0070f4;
      margin-top: 11vw;
      font-weight: 600;
    }
  }
}
</style>