<template>
  <div class="index-banner">
    <el-carousel height="100vw" class="mobile-carousel">
      <el-carousel-item v-for="banner in bannerList" :key="banner">
        <div class="banner-content">
          <img class="banner-img" :src="banner" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel height="35vw" class="pc-carousel">
      <el-carousel-item v-for="banner in pcBannerList" :key="banner">
        <div class="banner-content">
          <img class="banner-img" :src="banner" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="box">
      <div class="name">一猫一狗</div>
      <div class="description">专注宠物医疗IT解决方案,致力于赋能宠物医院</div>
      <el-button class="know-more-btn" type="primary" @click="goPage"
        >了解更多</el-button
      >
    </div>
  </div>
</template>
<script>
import banner1 from "../../assets/images/banner_1.png";
import banner2 from "../../assets/images/banner_2.png";
import banner3 from "../../assets/images/banner_3.png";
import pcBanner1 from "../../assets/images/pc_banner_1.png";
import pcBanner2 from "../../assets/images/pc_banner_2.png";
import pcBanner3 from "../../assets/images/pc_banner_3.png";
export default {
  name: "indexBanner",
  data() {
    return {
      bannerList: [banner1, banner2, banner3],
      pcBannerList: [pcBanner1, pcBanner2, pcBanner3],
    };
  },
  methods: {
    goPage() {
      this.$emit("goPage", "prod");
    },
  },
};
</script>
<style lang="less">
.index-banner {
  .el-carousel__indicator--horizontal {
    @media screen and (orientation: portrait) {
      padding: 0 0.5vw;
    }
    @media screen and (orientation: landscape) {
      padding: 0 0.1vw;
    }
  }
  .el-carousel__indicators--horizontal {
    @media screen and (orientation: portrait) {
      top: 80vw;
      left: 13vw;
    }
    @media screen and (orientation: landscape) {
      top: 26vw;
      left: 16.8vw;
    }
  }
  .el-carousel__button {
    border-radius: 2vw;
    @media screen and (orientation: portrait) {
      width: 4vw;
      height: 1vw;
    }
    @media screen and (orientation: landscape) {
      width: 1vw;
      height: 0.3vw;
    }
  }
  .el-carousel__indicator.is-active button {
    background: #208ffd;
  }
}
</style>
<style lang="less" scoped>
.index-banner {
  position: relative;
  .mobile-carousel {
    @media screen and (orientation: landscape) {
      display: none;
    }
  }
  .pc-carousel {
    @media screen and (orientation: portrait) {
      display: none;
    }
  }
  .banner-content {
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
    @media screen and (orientation: portrait) {
      padding-bottom: 100%;
    }
    @media screen and (orientation: landscape) {
      padding-bottom: 35%;
    }
    .banner-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .box {
    position: absolute;
    @media screen and (orientation: portrait) {
      top: 45%;
      width: calc(100% - 11vw);
    }
    @media screen and (orientation: landscape) {
      top: 40%;
      width: calc(100% - 30vw);
    }
    left: 50%;
    transform: translate(-50%);
    text-align: left;
    color: #fff;
    z-index: 2;
    .name {
      font-weight: 600;
      @media screen and (orientation: portrait) {
        font-size: 8vw;
        line-height: 10vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 2.5vw;
        line-height: 3vw;
      }
    }
    .description {
      margin-bottom: 10px;
      letter-spacing: -0.25px;
      @media screen and (orientation: portrait) {
        font-size: 5vw;
        line-height: 7vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 2vw;
        line-height: 3.5vw;
      }
    }
    .know-more-btn {
      @media screen and (orientation: portrait) {
        font-size: 3.3vw;
        padding: 2vw 3vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 1.3vw;
        padding: 0.8vw 1.3vw;
      }
    }
  }
}
</style>