<template>
  <div class="public-footer">
    <div class="footer-content">
      <div class="part" @click="handleClick('index')">
        <BasicSvg iconClass="logo-black" className="logo" />
        <div class="little-text logo-bottom-text">专注 专业 数据化 智能化</div>
      </div>
      <template v-if="nav == 'contact' && isPortrait">
        <div
          class="part"
          v-for="(item, index) in list.slice(0, 2)"
          :key="'item' + index"
          @click="handleClick(item.value)"
        >
          <div class="title">{{ item.title }}</div>
          <div
            class="little-text"
            v-for="(name, index) in item.names"
            :key="'name' + index"
          >
            {{ name }}
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="part"
          v-for="(item, index) in list"
          :key="'item' + index"
          @click="handleClick(item.value)"
        >
          <div class="title">{{ item.title }}</div>
          <div
            class="little-text"
            v-for="(name, index) in item.names"
            :key="'name' + index"
          >
            {{ name }}
          </div>
          <div v-if="item.icon" class="icon-content">
            <img src="../../assets/images/wx-qrcode.png" class="qrcode" />
          </div>
        </div>
      </template>
    </div>
    <div class="bottom">Copyright ©2022成都一猫一狗科技有限公司版权所有</div>
  </div>
</template>
<script>
export default {
  name: "publicFooter",
  props: ["nav"],
  computed: {
    isPortrait() {
      return JSON.parse(localStorage.getItem("1mao1gou.isPortrait")) || "";
    },
  },
  data() {
    return {
      list: [
        {
          title: "产品",
          value: "saas",
          names: ["SaaS管理系统"],
        },
        {
          title: "关于我们",
          value: "about",
          names: ["企业介绍", "核心团队"],
        },
        {
          title: "联系我们",
          value: "contact",
          names: [
            "15201478450",
            "yichen@1mao1gou.com",
            "北京市朝阳区高碑店北花园388号华汇大厦B座五层",
          ],
        },
        {
          title: "扫码关注",
          names: ["关注微信公众号、了解最新资讯，享优质服务咨询答疑"],
          icon: "wx-qrcode",
        },
      ],
    };
  },
  methods: {
    handleClick(val) {
      if (val) {
        if (val == "saas") {
          window.open("https://vets.chongaipet.com/login", "_blank");
        } else {
          this.$emit("goPage", val);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.public-footer {
  text-align: left;
  border-top: 1px solid #e2e9ed;
  box-sizing: border-box;
  @media screen and (orientation: portrait) {
    padding-top: 10vw;
  }
  .footer-content {
    @media screen and (orientation: landscape) {
      display: flex;
      padding: 2vw 15vw 3vw;
      justify-content: space-between;
    }
  }
  .part {
    @media screen and (orientation: portrait) {
      width: 88vw;
      margin: 0 auto;
      margin-bottom: 5.5vw;
    }
    @media screen and (orientation: landscape) {
      width: 17%;
      cursor: pointer;
      &:hover {
        color: #208ffd;
      }
    }
    .logo {
      @media screen and (orientation: portrait) {
        width: 32vw;
        height: 10vw;
      }
      @media screen and (orientation: landscape) {
        width: 8vw;
        height: 2vw;
        cursor: pointer;
      }
    }
    .title {
      color: #333;
      font-weight: 600;
      @media screen and (orientation: portrait) {
        font-size: 4vw;
        padding-left: 9.5vw;
        margin-bottom: 2vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 1vw;
        margin-top: 0.2vw;
        margin-bottom: 0.7vw;
        &:hover {
          color: #208ffd;
        }
      }
    }
    .little-text {
      color: #666;
      @media screen and (orientation: portrait) {
        font-size: 3.5vw;
        padding: 0 9.5vw;
        margin-bottom: 2vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 0.8vw;
        margin-bottom: 0.8vw;
        &:hover {
          color: #208ffd;
        }
      }
    }
    .logo-bottom-text {
      @media screen and (orientation: landscape) {
        padding-left: 2.4vw;
        &:hover {
          color: #208ffd;
        }
      }
    }
    .icon-content {
      border: 1px solid #e2e9ed;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (orientation: portrait) {
        width: 18vw;
        height: 18vw;
        margin-left: 9.5vw;
      }
      @media screen and (orientation: landscape) {
        width: 5vw;
        height: 5vw;
      }
      .qrcode {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    border-top: 1px solid #e2e9ed;
    text-align: center;
    box-sizing: border-box;
    color: #666;
    @media screen and (orientation: portrait) {
      padding: 3vw;
      font-size: 3.5vw;
    }
    @media screen and (orientation: landscape) {
      padding: 0.5vw;
      font-size: 0.8vw;
    }
  }
}
</style>