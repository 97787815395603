<template>
  <div class="production-introduction">
    <div class="float-right-row float-right-row-saas">
      <div class="img-box img-box-saas">
        <BasicSvg iconClass="saas" className="img" />
      </div>
      <div class="details-box details-box-saas">
        <div class="title">
          医疗、营销、管理、采购一体化系统 帮助宠物医院实现业务提升
        </div>
        <div class="remark remark-saas">Integrated system</div>
        <div class="remark remark-saas">
          支持不同规模、直营 连锁或加盟连锁的宠物医院管理
        </div>
      </div>
    </div>
    <div class="float-right-row">
      <div class="details-box float-right-box">
        <div class="icon-box">
          <BasicSvg iconClass="appointment-small" className="small-icon" />
        </div>
        <div class="title">预约功能</div>
        <div class="remark">Make an appointment</div>
        <div class="remark" style="margin-bottom: 4vw">
          可主动为客户创建预约，也可根据业务设置自动创建应预约，并发送提醒
        </div>
      </div>
      <div class="img-box">
        <BasicSvg iconClass="appointment" className="img" />
      </div>
    </div>
    <div class="float-left-row">
      <div class="details-box">
        <div class="icon-box">
          <BasicSvg iconClass="reception-small" className="small-icon" />
        </div>
        <div class="title">顾客接待</div>
        <div class="remark">Customer reception</div>
        <div class="remark" style="margin-bottom: 4vw">
          前台问询格式化，维护客户信息、宠物信息
        </div>
      </div>
      <div class="img-box">
        <BasicSvg iconClass="reception" className="img" />
      </div>
    </div>
    <div class="float-right-row">
      <div class="details-box float-right-box">
        <div class="icon-box">
          <BasicSvg iconClass="diagnosis-small" className="small-icon" />
        </div>
        <div class="title">诊疗规范</div>
        <div class="remark">Diagnosis and treatment standard</div>
        <div class="remark" style="margin-bottom: 4vw">
          诊疗标准健康档案：结合软件建立标准的诊疗规范
        </div>
      </div>
      <div class="img-box">
        <BasicSvg iconClass="diagnosis" className="img" />
      </div>
    </div>
    <div class="more-service-panel">
      <div class="title">更多服务</div>
      <div class="remark">More service</div>
      <div class="services-box">
        <div class="service" v-for="service in services" :key="service.icon">
          <div class="icon-box">
            <BasicSvg :iconClass="service.icon" className="small-icon" />
          </div>
          <div class="service-details">
            <div class="service-name">{{ service.name }}</div>
            <div class="remark" style="padding: 0">
              {{ service.remark }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "productionIntroduction",
  data() {
    return {
      services: [
        {
          icon: "hospital",
          name: "住院管理",
          remark: "住院宠物每日体征指标 用药医嘱、治疗计划的管理",
        },
        {
          icon: "collect",
          name: "收款",
          remark: "前台创建订单，售卖商品、服务",
        },
        {
          icon: "insect",
          name: "免疫驱虫",
          remark: "免疫驱虫计划创建、编辑、接种疫苗、执行驱虫",
        },
        {
          icon: "stock",
          name: "库存管理",
          remark: "住院宠物每日体征指标 用药医嘱、治疗计划的管理",
        },
        {
          icon: "purchase",
          name: "采购管理",
          remark: "采购申请审批采购订单汇总、预算管理",
        },
        {
          icon: "laboratory",
          name: "化验室管理",
          remark:
            "多维度的数据报表及看板，帮助机构通过数据驱动提升，关键指标和运营效率",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.production-introduction {
  @media screen and (orientation: landscape) {
    padding-top: 5vw;
  }
  .float-right-row,
  .float-left-row {
    @media screen and (orientation: portrait) {
      padding: 5vw 0;
    }
    @media screen and (orientation: landscape) {
      padding-bottom: 8vw;
    }
  }
  .float-right-row {
    @media screen and (orientation: landscape) {
      padding-left: 15vw;
      padding-right: 15vw;
    }
  }
  .float-right-row-saas {
    @media screen and (orientation: landscape) {
      padding-right: 11vw;
      padding-bottom: 17vw;
    }
  }
  .float-right-box {
    @media screen and (orientation: landscape) {
      float: right;
    }
  }
  .float-left-row {
    @media screen and (orientation: landscape) {
      display: flex;
      padding-left: 15vw;
      padding-right: 15vw;
    }
  }
  .img-box {
    overflow: hidden;
    position: relative;
    @media screen and (orientation: portrait) {
      width: 55vw;
      height: 35vw;
      margin: 0 auto;
    }
    @media screen and (orientation: landscape) {
      width: 28vw;
      height: 18vw;
    }
    .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .img-box-saas {
    @media screen and (orientation: portrait) {
      width: 100vw;
      height: 69vw;
    }
    @media screen and (orientation: landscape) {
      width: 58%;
      height: 28vw;
      float: right;
    }
  }
  .details-box {
    @media screen and (orientation: portrait) {
      padding: 0 5vw;
    }
    @media screen and (orientation: landscape) {
      width: 50%;
      text-align: left;
      padding-top: 3vw;
    }
  }
  .details-box-saas {
    @media screen and (orientation: landscape) {
      padding-top: 5vw;
      box-sizing: border-box;
    }
  }
  .title {
    color: #232536;
    font-weight: 600;
    @media screen and (orientation: portrait) {
      font-size: 5.5vw;
      line-height: 7.5vw;
      margin-bottom: 1vw;
    }
    @media screen and (orientation: landscape) {
      font-size: 1.5vw;
      line-height: 2.3vw;
    }
  }
  .remark {
    opacity: 0.6;
    @media screen and (orientation: portrait) {
      padding: 0 7vw;
      line-height: 6vw;
      font-size: 4vw;
      margin-bottom: 1vw;
    }
    @media screen and (orientation: landscape) {
      font-size: 1vw;
      line-height: 2vw;
    }
  }
  .remark-saas {
    @media screen and (orientation: portrait) {
      font-size: 4.5vw;
      margin-top: 1vw;
      margin-bottom: 3vw;
    }
    @media screen and (orientation: landscape) {
      font-size: 1vw;
      margin-top: 0.1vw;
      width: 50%;
      line-height: 1.8vw;
    }
  }
  .icon-box {
    @media screen and (orientation: portrait) {
      margin-bottom: 2vw;
    }
    @media screen and (orientation: landscape) {
      margin-bottom: 0.5vw;
      .small-icon {
        width: 3vw;
        height: 3vw;
      }
    }
  }
  .more-service-panel {
    background: #f4f6fc;
    box-sizing: border-box;
    @media screen and (orientation: portrait) {
      padding: 7vw 3vw 10vw;
      margin-top: 5vw;
    }
    @media screen and (orientation: landscape) {
      padding: 2vw 15vw 3vw;
      .title {
        font-size: 2vw;
      }
      .remark {
        font-size: 1.1vw;
      }
    }
  }
  .services-box {
    @media screen and (orientation: landscape) {
      margin-top: 1vw;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .service {
      box-sizing: border-box;
      box-shadow: 0px 20px 25px -5px rgba(128, 144, 186, 0.16);
      background: #fff;
      @media screen and (orientation: portrait) {
        padding: 5vw 5vw 5vw 8vw;
        border-radius: 3vw;
        display: flex;
        width: 100%;
        margin-top: 5vw;
      }
      @media screen and (orientation: landscape) {
        margin-bottom: 1.6vw;
        border-radius: 0.4vw;
        width: 22vw;
        padding: 1.3vw 1.3vw 1vw;
      }
      .icon-box {
        flex-shrink: 0;
        @media screen and (orientation: landscape) {
          margin-bottom: 0;
        }
        .small-icon {
          @media screen and (orientation: portrait) {
            width: 12vw;
            height: 12vw;
          }
          @media screen and (orientation: landscape) {
            width: 3vw;
            height: 3vw;
          }
        }
      }
      .service-details {
        @media screen and (orientation: portrait) {
          text-align: left;
          margin-left: 5vw;
        }
        @media screen and (orientation: landscape) {
          text-align: center;
        }
        .service-name {
          font-weight: 600;
          margin-bottom: 0.5vw;
          @media screen and (orientation: portrait) {
            font-size: 4.2vw;
          }
          @media screen and (orientation: landscape) {
            font-size: 1.3vw;
          }
        }
        .remark {
          @media screen and (orientation: portrait) {
            font-size: 3.7vw;
            line-height: 6vw;
          }
          @media screen and (orientation: landscape) {
            font-size: 1vw;
            line-height: 2vw;
          }
        }
      }
    }
  }
}
</style>