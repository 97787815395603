<template>
  <div class="first-index">
    <div class="one-row">
      <div class="title">一站式行业解决方案</div>
      <div class="remark">One-stop industry solution</div>
      <div class="remark">
        全业务场景覆盖 高度贴合宠物医疗行业 针对性解决企业痛点
      </div>
    </div>
    <div class="second-row">
      <div
        class="second-row-col"
        v-for="(column, index) in secondRow"
        :key="'secondRow' + index"
      >
        <BasicSvg :iconClass="column.icon" className="icon" />
        <div class="title">{{ column.title }}</div>
        <div v-for="(item, index) in column.items" :key="'item' + index">
          <div class="name">{{ item.name }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="third-row">
      <div
        class="third-row-col"
        v-for="(column, index) in thirdRow"
        :key="'column' + index"
      >
        <BasicSvg :iconClass="column.icon" className="icon" />
        <div class="text-part">
          <div class="name">{{ column.name }}</div>
          <div class="remark">
            {{
              !isPortrait && column.remark_pc ? column.remark_pc : column.remark
            }}
          </div>
        </div>
      </div>
    </div>
    <img
      v-if="isPortrait"
      src="../../assets/images/contact-us.png"
      class="contact-bg"
    />
    <img
      v-else
      src="../../assets/images/contact-us-pc.png"
      class="contact-bg"
    />
  </div>
</template>
<script>
export default {
  name: "firstIndex",
  data() {
    return {
      secondRow: [
        {
          icon: "flow",
          title: "流量赋能",
          items: [
            {
              name: "老带新",
              description: "自定义返现规则，激发用户自主传播",
            },
            {
              name: "社群裂变",
              description: "社群运营工具赋能，打造私域流量闭环",
            },
            {
              name: "客户召回",
              description: "系统筛选流失客户，一键触发召回方案",
            },
          ],
        },
        {
          icon: "safe",
          title: "安全赋能",
          items: [
            {
              name: "用户安全",
              description: "用户手机虚拟保护，防信息泄漏",
            },
            {
              name: "账号安全",
              description: "MAC、IP地址登陆设置，防盗号",
            },
            {
              name: "数据安全",
              description: "系统数据自动云端备份，防丟失",
            },
          ],
        },
        {
          icon: "manage",
          title: "管理赋能",
          items: [
            {
              name: "数据罗盘",
              description: "自动推送日经营报表，运营数据一目了然",
            },
            {
              name: "风险预警",
              description: "营指标未达标项、库存不足...及时预警",
            },
            {
              name: "辅助诊疗",
              description: "智能推荐诊疗方案，为企业节省人力成本",
            },
          ],
        },
      ],
      thirdRow: [
        {
          icon: "data",
          name: "数据洞察",
          remark: "技术领先驱动业务增长",
        },
        {
          icon: "smart",
          name: "智能化",
          remark: "专注专业数据化智能化",
        },
        {
          icon: "closed-cycle",
          name: "业务闭环",
          remark: "全方位满足业务需求，形成闭环效应",
          remark_pc: "全方位满足业务需求",
        },
        {
          icon: "refine",
          name: "精细化运营",
          remark: "各类报表精密分析，多维度展现最大程度优化工作",
          remark_pc: "门店/人效数据多维度剖析",
        },
      ],
      isPortrait: true,
    };
  },
  methods: {
    getIsPortrait() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      this.isPortrait = width > height ? false : true;
      localStorage.setItem("1mao1gou.isPortrait", this.isPortrait);
    },
  },
  mounted() {
    this.getIsPortrait();
    window.addEventListener("resize", this.getIsPortrait);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getIsPortrait);
  },
};
</script>
<style lang="less" scoped>
.first-index {
  width: 100%;
  @media screen and (orientation: portrait) {
    padding-top: 7vw;
  }
  @media screen and (orientation: landscape) {
    padding-top: 5vw;
  }
  .one-row {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 6vw;
    .title {
      font-weight: 600;
      color: #23354d;
      @media screen and (orientation: portrait) {
        font-size: 7.5vw;
        line-height: 10vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 3vw;
        line-height: 3.5vw;
      }
    }
    .remark {
      font-weight: 500;
      width: 80%;
      margin: 0 auto;
      opacity: 0.7;
      @media screen and (orientation: portrait) {
        font-size: 3.5vw;
        line-height: 7vw;
      }
      @media screen and (orientation: landscape) {
        font-size: 1.3vw;
        line-height: 2vw;
      }
    }
  }
  .icon {
    flex-shrink: 0;
    @media screen and (orientation: landscape) {
      width: 2.5vw;
      height: 2.5vw;
    }
  }
  .second-row {
    margin: 0 auto;
    @media screen and (orientation: landscape) {
      display: flex;
      width: 70vw;
      justify-content: space-between;
    }
    @media screen and (orientation: portrait) {
      width: 90vw;
    }
    .second-row-col {
      @media screen and (orientation: portrait) {
        width: 100%;
        padding: 7vw 8.5vw 8vw;
        border-radius: 2vw;
      }
      @media screen and (orientation: landscape) {
        width: 32%;
        padding: 1.8vw 2.3vw 2.1vw;
        border-radius: 1vw;
      }
      background: #f4f6fc;
      text-align: left;
      box-sizing: border-box;
      color: #060606;
      margin-bottom: 2.5vw;
      .title {
        font-weight: 600;
        @media screen and (orientation: portrait) {
          font-size: 7vw;
          line-height: 10vw;
          margin-top: 3vw;
          margin-bottom: 2vw;
        }
        @media screen and (orientation: landscape) {
          font-size: 1.2vw;
          line-height: 3vw;
        }
      }
      .name {
        font-weight: 600;
        opacity: 0.7;
        @media screen and (orientation: portrait) {
          font-size: 4.8vw;
          line-height: 10vw;
        }
        @media screen and (orientation: landscape) {
          font-size: 1vw;
          line-height: 1.8vw;
        }
      }
      .description {
        opacity: 0.7;
        @media screen and (orientation: portrait) {
          font-size: 3.6vw;
          line-height: 5vw;
        }
        @media screen and (orientation: landscape) {
          font-size: 0.8vw;
          line-height: 1.8vw;
        }
      }
    }
  }
  .third-row {
    margin: 0 auto;
    @media screen and (orientation: portrait) {
      width: 82vw;
      margin-top: 7vw;
    }
    @media screen and (orientation: landscape) {
      width: 70vw;
      margin-top: 2vw;
      display: flex;
      justify-content: space-between;
    }
    .third-row-col {
      display: flex;
      align-items: center;
      margin-bottom: 5vw;
      @media screen and (orientation: landscape) {
        flex-direction: column;
        width: 25%;
      }
      .text-part {
        @media screen and (orientation: portrait) {
          margin-left: 5vw;
          text-align: left;
        }
        .name {
          font-weight: 600;
          @media screen and (orientation: portrait) {
            font-size: 5vw;
          }
          @media screen and (orientation: landscape) {
            font-size: 1.2vw;
            margin-top: 1vw;
          }
        }
        .remark {
          color: #282938;
          opacity: 0.7;
          @media screen and (orientation: portrait) {
            font-size: 3.5vw;
            margin-top: 1vw;
          }
          @media screen and (orientation: landscape) {
            font-size: 0.8vw;
            margin-top: 0.7vw;
          }
        }
      }
    }
  }
  .contact-bg {
    @media screen and (orientation: portrait) {
      width: 100vw;
      height: 100vw;
    }
    @media screen and (orientation: landscape) {
      width: 74.5vw;
      height: 14vw;
    }
  }
}
</style>