<template>
  <div id="app">
    <TotalIndex />
  </div>
</template>
<script>
import TotalIndex from "./views/TotalIndex.vue";
export default {
  name: "App",
  components: { TotalIndex },
};
</script>
<style lang="less">
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #282938;
}
</style>